<template>
    <div class="SetChip fixed position-center flex column">
        <div class="margin-auto mat15 mab15 flex items-center content-center">
            <img class="allh" :src="'./img/Common/title_szcm'+ (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        </div>
        <div class="flex1">
            <div class="flex1 flex warp chipbox">
                <div v-for="(item,index) in ChipPond" class="chipone flex items-center content-center pointer">
                    <div :class="{'currentChip':Already.includes(item)}" @click="ChangeChip(item)"
                         class="flex items-center content-center">
                        <img :src="'./img/Common/'+item+'.png'">
                    </div>
                </div>
            </div>
            <div class="canclebox font24 flex content-between items-center">
                <span @click="ChipChange" class="pointer text-center hover opacity8">{{Lang['QD']}}<!--确定--></span>
                <span @click="ShowChip" class="pointer text-center hover opacity8">{{Lang['QX']}}<!--取消--></span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'SetChip',
        props: ['ShowChip', 'ShowChipPond', 'MaxNum', 'Count'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                ChipPond: [1, 5, 10, 20, 50, 100, 500, 1000, 5000, 10000, 50000, 100000, 500000, 1000000],
                Already: [],
                RootChip: [],
            }
        },
        watch: {
            'ShowChipPond': {
                handler(n, o) {
                    if (n === true) {
                        this.RootChip = this.ToParse(localStorage.Chip);
                        this.Already = this.RootChip[this.MaxNum === 6 ? 0 : (this.Count + 1)];
                    }
                }, immediate: true
            }
        },
        methods: {
            ChangeChip(item) {
                let index = this.Already.indexOf(item);
                if (index < 0) {  //里面没有这一项
                    if (this.Already.length >= this.MaxNum) {
                        if (this.LangeuageAgent === 'CH') {
                            this.Toast('4', `最多选择${this.MaxNum}个筹码`);
                        } else {
                            this.Toast('4', `Đến${this.MaxNum}`);
                        }
                        return;
                    }
                    this.Already = [...this.Already, ...[item]].sort();
                }
                if (index >= 0) this.Already.splice(index, 1);
            },
            ChipChange() { //修改筹码
                if (this.Already.length === 0) {
                    // this.toast('', '至少选择一个筹码', 3);
                    return;
                }
                this.RootChip[this.MaxNum === 6 ? 0 : (this.Count + 1)] = this.Already.sort((a, b) => a - b);
                localStorage.Chip = this.ToString(this.RootChip);
                this.SetStore({
                    Prop: 'ChangeChip',
                    Val: true
                });
                this.ShowChip();
            }
        }
    }
</script>

<style lang="less">
    .SetChip {
        width: 8.6rem;
        height: 4.5rem;
        background-color: rgba(0, 0, 0, .6);
        border: solid 1px #8e7056;
        z-index: 100;

        > div:first-child {
            width: 4.2rem;
            height: .35rem;
            background: url("../../../public/img/Common/gn_tt.png") no-repeat top center/cover;
        }

        > div:last-child {
            border-top: solid 1px #8e7056;
            background-color: #000;

            .chipbox {
                padding: .6rem .6rem 0 .6rem;

                .chipone {

                    flex: 0 0 1/7*100%;
                    margin-bottom: .3rem;

                    div {
                        width: .85rem;
                        height: .85rem;
                        border-radius: 50%;

                        &.currentChip {
                            background: url("../../../public/img/Common/cm_bg_hover.png") no-repeat center center/cover;
                        }

                        &:hover {
                            background: url("../../../public/img/Common/cm_bg_hover.png") no-repeat center center/cover;
                        }

                        img {
                            width: .65rem;
                            height: .65rem;
                        }
                    }

                }
            }

            .canclebox {
                padding: 0 2.1rem 0 2.1rem;

                span {
                    height: .6rem;
                    width: 1.55rem;
                    line-height: .6rem;
                }

                span:first-child {
                    background: url("../../../public/img/Common/queren_btn_bg.png") no-repeat top center/cover;
                    color: #291c03;
                }

                span:last-child {
                    background: url("../../../public/img/Common/quxiao_btn_bg.png") no-repeat top center/cover;
                    color: #c5c5c5;
                }
            }
        }
    }
</style>
